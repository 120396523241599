<template>
  <div class="container-fluid">
    <div v-if="loading">
      <b-spinner large label="Large Spinner"></b-spinner>
      <div>{{ $t('loading') }}</div>
    </div>
    <div v-else-if="hasInvoices" class="row">
      <div class="col-md-6 col-12 mb-4" v-for="(invoice, index) in invoices" :key="index">
          <invoice :type="invoice.type" :refunded="invoice.date_refunded !== null" :order-hash="orderHash" />
      </div>
    </div>
    <div v-else class="mx-auto no-requirements">
      <h4>{{ $t('invoice_empty_heading') }}</h4>
      <i class="fa-solid fa-file-invoice fa-3x"></i>
    </div>
  </div>
</template>

<script>
import ApiService from '../../apiService.js'
import Invoice from '@/components/MyAccount/Invoice'

export default {
  name: 'Invoices',
  components: { Invoice },
  props: {
    orderHash: String
  },
  mounted () {
    this.getInvoice()
  },
  data () {
    return {
      invoices: [],
      loading: true
    }
  },
  methods: {
    getInvoice () {
      this.loading = true
      this.invoices = []
      ApiService.getInvoices(this.orderHash).then(response => {
        this.loading = false

        let data = []
        response.data.data.forEach(
          function (element) {
            if (typeof element.date_refunded === 'undefined') {
              element.date_refunded = null
            } else if (element.date_refunded !== null) {
              let newElement = {}
              Object.assign(newElement, element)
              newElement.date_refunded = null
              data.push(newElement)
            }

            data.push(element)
          })

        this.invoices = data
      }).catch(() => {
        this.loading = false
      })
    }
  },
  computed: {
    hasInvoices () {
      return this.invoices.length !== 0 && this.invoices[0] !== null
    }
  }

}
</script>
