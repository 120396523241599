<template>
    <b-modal
      :id="modalId"
      ref="acceptModal"
      :title="$t('reviews_form_title')"
      hide-footer
      @show="resetModal"
      @hidden="resetModal"
      size="md"
    >
      <div class=" mx-3 pb-3">
        <div id="task-form-error-details" class=" p-1 alert alert-danger text-center m-3" v-if="validation_message_switch">
          <p class="p-0 m-0">{{validation_message}}</p>
        </div>

        <div class="field-heading pt-2">{{$t('reviews_form_rating_heading')}}</div>
        <star-rating
          v-model="rating"
          v-bind:star-size="20"
          v-bind:increment="1"
        />

        <div class="field-heading pt-2 pb-3">{{$t('reviews_form_message_heading')}}</div>

        <b-form-input
          id="textarea"
          v-model="title"
          :placeholder="$t('reviews_placeholder_title')"
          class="m-0 mb-3"
          v-bind:class="{ 'border-danger': (validation_title === true)}"
        />

        <b-form-textarea
          id="textarea"
          v-model="text"
          :placeholder="$t('reviews_placeholder')"
          rows="3"
          max-rows="6"
          class="m-0"
          v-bind:class="{ 'border-danger': (text_validation === true)}"
        />
        <div class="pt-2 small">{{ $t('characters_remaining') }}: <span v-bind:style=" text.length > 400 ? 'color: red;' : 'color: green;' ">{{ 400 - this.text.length }}</span></div>

        <b-form-checkbox
          id="portfolioPermission"
          v-model="portfolioPermission"
          :value="true"
          :unchecked-value="false"
          class="pt-3"
        >
          {{$t('reviews_portfolio_message')}}
        </b-form-checkbox>

        <b-button v-if="!submit" variant="primary" @click="submitReview" :disabled="text.length > 400" block class="w-100 mt-3">{{ $t('review_form_button') }}</b-button>
        <b-button v-else variant="primary" disabled="disabled" block class="w-100 mt-3">
          <b-spinner class="" small label="Small Spinner"/>
        </b-button>
      </div>
    </b-modal>
</template>

<script>
import apiService from '../../apiService.js'
import StarRating from 'vue-star-rating'
export default {
  name: 'accept',
  props: {
    hash: String,
    modalId: {
      type: String,
      required: false,
      default: 'accept'
    }
  },
  components: {
    StarRating
  },
  data () {
    return {
      rating: 0,
      submit: false,
      show: true,
      portfolioPermission: false,
      text: '',
      title: null,
      text_validation: false,
      validation_message: '',
      validation_rating: '',
      validation_message_switch: false,
      validation_title: false
    }
  },
  methods: {
    resetModal () {
      this.text = ''
      this.title = null
      this.submit = false
      this.rating = 0
      this.portfolioPermission = false
    },
    submitReview () {
      this.validation_message_switch = false
      this.text_validation = false
      this.submit = true
      if (this.text.length === 0) {
        this.text_validation = true
        this.validation_message = this.$t('message_validation')
        this.validation_message_switch = true
        this.submit = false
      } else if (this.rating === null || this.rating < 1 || this.rating > 5) {
        this.validation_message = this.$t('rating_validation')
        this.validation_message_switch = true
        this.submit = false
      } else {
        let uploadData = {
          'rating': this.rating,
          'message': this.text,
          'portfolio_permission': this.portfolioPermission ? 1 : 0
        }

        if (this.title !== null && this.title.length !== 0) {
          uploadData['title'] = this.title
        }

        apiService.submitReview(this.hash, uploadData).then((data) => {
          if (this.modalId === 'create-review') {
            this.$emit('updateReview', data.data.data[0])
          } else {
            this.$parent.accepted(true)
          }
          this.$bvModal.hide(this.modalId)
        }).catch((error) => {
          let switchOn = false
          if (error.response.data.code === 'HTTP-400') {
            if (error.response.data.errors.children.message.errors) {
              this.validation_message = error.response.data.errors.children.message.errors[0]
              switchOn = true
              this.text_validation = true
            } else if (error.response.data.errors.children.rating.errors) {
              this.validation_rating = error.response.data.errors.children.rating.errors[0]
              switchOn = true
            } else if (error.response.data.errors.children.title.errors) {
              this.validation_message = error.response.data.errors.children.title.errors[0]
              switchOn = true
              this.validation_title = true
            }
          }
          if (error.response.data.error_code === 'HTTP-409') {
            this.validation_message = error.response.data.error_message
            switchOn = true
          }
          if (switchOn) {
            this.validation_message_switch = true
            this.submit = false
          }
        })
      }
    }
  }
}
</script>

<style>
label[for=portfolioPermission] {
  font-weight: unset !important;
}
</style>
