<template>
    <div>
      <div class="row d-block">
          <img :src="getImage" class="w-50 h-50" :alt="getTitle" :title="getTitle" />
          <p class="text-center w-100">{{ getTitle }}</p>
      </div>
      <div class="row">
        <template v-if="preloader">
          <div class="btn btn-primary col-12">
            <b-spinner small label="Small Spinner" />
          </div>
        </template>
        <template v-else>
          <div class="col-6">
            <b-button class="w-100" variant="outline-primary" @click="viewInvoice('html')">{{ $t('view') }}</b-button>
          </div>
          <div class="col-6">
            <b-button class="w-100" variant="outline-primary" @click="viewInvoice('pdf')">{{ $t('download') }}</b-button>
          </div>
        </template>
      </div>
    </div>
</template>

<script>
import ApiService from '@/apiService'

export default {
  name: 'Invoice',
  props: {
    orderHash: String,
    type: Number,
    refunded: Boolean
  },
  data () {
    return {
      preloader: false
    }
  },
  methods: {
    viewInvoice (format) {
      this.preloader = true
      ApiService.downloadInvoices(this.orderHash, this.type, format, this.refunded).then(response => {
        if (format === 'pdf') {
          let a = document.createElement('a')
          a.href = 'data:application/pdf;base64,' + response.data.data // Base64, removing slashes
          a.download = 'invoice_' + this.orderHash + '.pdf' // File name Here
          a.click() // Downloaded file
        } else if (format === 'html') {
          let newWin = open()
          newWin.document.write(response.data.data)
        }
        this.preloader = false
      }).catch(() => {
        this.preloader = false
      })
    }
  },
  computed: {
    getImage () {
      if (this.type === 2) {
        return require('@/assets/Invoice/' + (this.refunded ? 'refund-service-fee-icon.svg' : 'service-fee-icon.svg'))
      }
      return require('@/assets/Invoice/' + (this.refunded ? 'refund-task-icon.svg' : 'task-icon.svg'))
    },
    getTitle () {
      if (this.type === 2) {
        return this.refunded ? this.$t('invoice_refunded_service_fee') : this.$t('invoice_service_fee')
      }
      return this.refunded ? this.$t('invoice_refunded_task_fee') : this.$t('invoice_task_fee')
    }
  }
}
</script>
