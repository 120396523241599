<template>
    <div>
        <b-modal
                :id="refId"
                ref="modal"
                :title="$t('order_decision_model_title')"
                @show="resetModal"
                @hidden="resetModal"
                hide-footer
        >
            <div class="p-2 p-sm-3">
              <div v-if="ErrorMessageSwitch">
                <div class="alert alert-danger py-1 px-3 my-2">
                  <i class="fa-solid fa-triangle-exclamation"></i>
                  {{ ErrorMessage }}
                </div>
              </div>
                <label v-if="currentPageSold !== null">{{$t('decision_modal_requirements_heading')}}</label>
                <b-button v-if="currentPageSold !== null"  class="ml-auto mr-auto mt-0 mb-2  w-50 text-white border-0" variant="success" block @click="viewOrder">{{ $t("view_order_button") }}</b-button>
                <form ref="form" @submit.stop.prevent="handleSubmit">
                    <b-form-group
                            :state="messageState"
                            :label="$t('decision_modal_requirements_reason_label')"
                            label-for="Message"
                            :invalid-feedback="$t('message_validation')"
                    >
                        <p id="message-error-empty" class="d-none alert alert-danger text-dark">{{ $t('order_requirements_message_empty') }}</p>
                        <b-form-textarea
                            id="Message"
                            v-model="message"
                            :state="messageState"
                            required
                            rows="3"
                        ></b-form-textarea>
                    </b-form-group>
                </form>
                <div class="row" v-if="!showSpinnerAccept && !showSpinnerDecline">
                    <div class="col-6">
                        <b-button class=" ml-auto mr-auto mt-3 w-100 bg-success text-white border-0" block @click="handleAccept">{{ $t("accept_button") }}</b-button>
                    </div>
                    <div class="col-6">
                        <b-button  class="ml-auto mr-auto mt-3  w-100 text-white border-0" variant="danger" block @click="handleDecline">{{ $t("decline_button") }}</b-button>
                    </div>
                </div>
                <div class="row" v-else>
                  <div class="col-12">
                    <b-button class="ml-auto mr-auto mt-3  w-100 text-white border-0" variant="primary" block disabled><b-spinner class="" small label="Large Spinner"></b-spinner></b-button>
                  </div>
                </div>

            </div>
        </b-modal>
    </div>
</template>

<script>
import ApiService from '../../apiService.js'
export default {
  name: 'OrderDecisionModal',
  props: {
    refId: String,
    hash: String,
    messageThread: String,
    currentPageSold: {
      type: Number,
      required: false,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      message: '',
      messageState: null,
      submittedNames: [],
      file2: null,
      showSpinnerAccept: false,
      showSpinnerDecline: false,
      ErrorMessageSwitch: false,
      ErrorMessage: ''
    }
  },
  methods: {
    resetModal () {
      this.messageState = null
    },
    handleAccept (bvModalEvt) {
      this.showSpinnerAccept = true
      const el = document.getElementById('message-error-empty')
      el.classList.add('d-none')
      el.classList.remove('d-block')
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      let orderDecision = {
        'status': 1
      }
      this.handleSubmit(orderDecision)
    },
    viewOrder () {
      window.open('orders/receive/' + this.hash, '_blank')
    },
    handleDecline (bvModalEvt) {
      this.showSpinnerDecline = true
      const el = document.getElementById('message-error-empty')
      el.classList.add('d-none')
      el.classList.remove('d-block')
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      let orderDecision = {
        'status': 2
      }
      this.handleSubmit(orderDecision)
    },
    handleSubmit (data) {
      const el = document.getElementById('message-error-empty')
      if (this.message.length === 0) {
        this.showSpinnerAccept = false
        this.showSpinnerDecline = false
        el.classList.remove('d-none')
        el.classList.add('d-block')
        return
      }

      this.$nextTick(() => {
        let vm = this
        ApiService.postMessage(this.messageThread, this.message, null).then(response => {
          ApiService.postOrders(this.hash, data).then(() => {
            if (data.status === 1) {
              ApiService.postOrders(this.hash, { 'status': 3 }).then(() => {}).catch(() => {})
            }
            if (vm.currentPageSold !== null) {
              vm.$vnode.context.getOrdersSeller(vm.currentPageSold)
              vm.$vnode.context.getOrdersSellerPending(vm.currentPageSold, 0)
            } else {
              vm.$vnode.context.getOrder(vm.$refs.modal)
            }
          }).catch(() => {
          })
        }).catch((error) => {
          this.ErrorMessageSwitch = true
          let errors = error.response.data.errors.children
          if (errors.message.errors.length > 0) {
            this.ErrorMessage = errors.message.errors[0]
          }
          this.showSpinnerAccept = false
          this.showSpinnerDecline = false
        })
      })
    }
  }
}
</script>

<style scoped>
    h3{
        font-weight: 700;
        font-size: 14px;
        padding-bottom: 0;
        color: #0e0e0f;
        margin-bottom: 0;
    }
    p{
        padding-top: 5px;
        font-weight: 400;
        color: #999;
        font-size: 14px;
    }
  .requirements {
    word-wrap: break-word;
  }

</style>
