<template>
  <div>
    <div v-if="reviewData !== null">
      <TaskReviews
          :time-posted="reviewData.date_created"
          :title="reviewData.title"
          :comment="reviewData.message"
          :username="reviewData.author_user.username"
          :rating="reviewData.rating"
          :photo_path="reviewData.author_user.photo_path"
          :hash="reviewData.hash"
          :orders-page="ordersPage"
          :portfolio="portfolio"
          :buyer="buyer"
          :dispute="dispute"
          :orderReview=true
      />
    </div>
    <div v-else-if="buyer && orderStatus === 5">
      <h4>{{$t('review_empty_heading_buyer')}}</h4>
      <b-button class="mt-2" variant="primary" v-on:click="openModal()">{{$t('create_review_button')}}</b-button>
    </div>
    <div v-else>
      <h4>{{ $t('reviews_empty_heading') }}</h4>
      <i class="fa-solid fa-comments fa-3x mb-3"></i>
    </div>
    <accept :hash="orderHash" id="create-review" modalId="create-review" v-on:updateReview = "updateReview"/>
  </div>
</template>

<script>
import TaskReviews from '../Task/ReviewItem'
import accept from '../Modal/AcceptedModal'

export default {
  name: 'OrderReview',
  components: { TaskReviews, accept },
  props: {
    review: Object,
    orderHash: String,
    orderStatus: Number,
    ordersPage: {
      type: Boolean,
      required: false,
      default: true
    },
    portfolio: {
      type: Boolean,
      required: false,
      default: false
    },
    buyer: {
      type: Boolean,
      required: false,
      default: false
    },
    dispute: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      reviewData: this.review
    }
  },
  methods: {
    openModal () {
      this.$bvModal.show('create-review')
    },
    updateReview (data) {
      this.reviewData = data
    }
  }
}
</script>

<style scoped>

</style>
