<template>
  <div>
    <div class="receive-task container py-3" v-if="OrderReceive">
      <div class="mb-5 mt-1">
        <Breadcrumb v-if="breadcrumbs.length > 0" :breadcrumbList="breadcrumbs"/>
      </div>

      <b-alert v-if="currentOrderStatus.status === 7" show variant="warning">
        <i class="fa-solid fa-triangle-exclamation text-warning px-2"></i> {{ $t('order_in_dispute') }}
      </b-alert>

      <banner-alert :title="$t('receive_task.banner.complete.title')" :subtitle="$t('receive_task.banner.complete.subtitle')" :button="$t('receive_task.banner.complete.button')"
                    @buttonClicked="$bvModal.show('accept')" v-if="isBuyer && [4, 7].includes(currentOrderStatus.status) && Object.keys(order.order_asset).length"/>
      <banner-alert :title="$t('receive_task.banner.deliver.title')" :subtitle="$t('receive_task.banner.deliver.subtitle')" :button="$t('receive_task.banner.deliver.button')"
                    @buttonClicked="$router.push('/my-account/orders/deliver/' + order.hash)" v-if="isSeller && !deliveredStatusNotAllowed.includes(currentOrderStatus.status)"/>

      <div class="row">
        <div class="col-lg-4 col-md-12 mb-sm-3">
          <div class="side-details mb-2">
            <div>
              <div class="bg-gray border-bottom p-3">
                <h5 class="m-0">{{ $t('order_details') }}</h5>
              </div>
              <div class="bg-white p-3">
                <h4 class="font-weight-bold text-dark cursor-pointer" @click="getOriginalSlug()">
                  {{ order.items[0].gig_title }}
                </h4>

                <div class="border rounded small m-2 p-2">
                  <div class="d-flex justify-content-between pb-2">
                    <div>{{ $t('status') }}</div>
                    <div :class="getProcessWordingAndClass.class" class="font-weight-bold">
                      {{ getProcessWordingAndClass.name }}
                    </div>
                  </div>
                  <div class="d-flex justify-content-between">
                    <div>{{ $t('due_date') }}</div>
                    <div class="text-right">{{ due_date }}</div>
                  </div>
                  <div v-if="isOrderInAction() && dateDiffInDays(order.due_date) >= 0 && dateDiffInDays(order.due_date) <= 4" class="due-date mt-2">
                    <div v-if="dateDiffInDays(order.due_date) === 3" class="due-date-pill three">{{ $t('due_date_3_left') }}</div>
                    <div v-if="dateDiffInDays(order.due_date) === 2" class="due-date-pill two">{{ $t('due_date_2_left') }}</div>
                    <div v-if="dateDiffInDays(order.due_date) === 1" :class="{ one: isSeller }" class="due-date-pill">{{ $t('due_date_1_left') }}</div>
                    <div v-if="dateDiffInDays(order.due_date) === 0" :class="{ one: isSeller }" class="due-date-pill">{{ $t('due_date_0_left') }}</div>
                  </div>
                  <div v-else-if="isOrderPassDue()" class="text-danger mt-2 h6">
                    <i class="fa-solid fa-circle-exclamation px-2"></i>
                    {{ $t('past_due') }}
                  </div>
                </div>

                <div class="small pt-2">
                  <div class="d-flex justify-content-between pb-2">
                    <div class="font-weight-bold">{{ $t('revisions_left') }}</div>
                    <div>{{ revisions }}</div>
                  </div>
                  <div class="d-flex justify-content-between pb-2">
                    <div class="font-weight-bold">{{ $t('view_orders_table_heading_seller') }}</div>
                    <div>{{ order.seller_username }}</div>
                  </div>
                  <div class="d-flex justify-content-between pb-2">
                    <div class="font-weight-bold">{{ $t('view_orders_table_heading_buyer') }}</div>
                    <div>{{ order.buyer_username }}</div>
                  </div>
                  <div class="d-flex justify-content-between pb-2">
                    <div class="font-weight-bold">{{ $t('view_orders_table_heading_price') }}</div>
                    <div v-if="isBuyer">
                      {{ getDisplayPrice((order.price_total !== '0.00') ? order.price_total : order.price_subtotal, order.currency, order.currency_exchange_rate) }}
                    </div>
                    <div v-else class="text-right">
                      {{ getDisplayPrice(order.price_subtotal, 'USD', 1) }} <br> {{ order.price_etn.toFixed(2) }} ETN
                    </div>
                  </div>
                  <div v-if="isBuyer && order.currency !== 'USD'" class="d-flex justify-content-between pb-2">
                    <div class="font-weight-bold">{{ $t('view_orders_table_heading_billed_price') }}</div>
                    <div>{{ getDisplayPrice((order.price_total !== '0.00') ? order.price_total : order.price_subtotal, 'USD', 1) }}</div>
                  </div>
                  <div class="d-flex justify-content-between pb-2">
                    <div class="font-weight-bold">{{ $t('quantity') }}</div>
                    <div>{{ order.items[0].quantity }}</div>
                  </div>
                  <div class="d-flex justify-content-between">
                    <div class="font-weight-bold">{{ $t('order_reference') }}</div>
                    <div>#{{ order.hash }}</div>
                  </div>
                  <div class="mt-3" v-if="isBuyer && revisions > 0 && order.order_asset.length !== 0 && ![3, 5, 6].includes(currentOrderStatus.status)">
                    <b-button variant="outline-warning" class="w-100" @click="decision('decline')">{{ $t('decline') }}</b-button>
                  </div>
                  <div class="mt-3" v-if="!order.dispute && !disputeStatusNotAllowed.includes(currentOrderStatus.status)">
                    <b-button variant="outline-danger" class="w-100" @click="decision('dispute')">{{ $t('dispute_request') }}</b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-8 col-md-12">
          <b-card no-body>
            <b-tabs v-model="tabIndex" card>
              <b-tab :title="$t('timeline')">
                <Timeline :timelines="timeline"/>
              </b-tab>
              <b-tab :title="$t('requirements')">
                <Requirement :requirements="requirements" :hash="this.$route.params.hash" :order="order" :username="username" v-if="requirementsLoaded" :description="description"
                             :customQuoteRequirements="customQuote ? order.items[0].description : false"/>
                <div v-else class="mx-auto my-5">
                  <b-spinner large label="Large Spinner"></b-spinner>
                  <div>{{ $t('requirements_loading') }}</div>
                </div>
              </b-tab>
              <b-tab :title="$t('delivery')">
                <Delivery :deliveries="order.order_asset"/>
              </b-tab>
              <b-tab v-if="order.thread" class="pt-1 pb-0" v-lazy-container="{ selector: 'img' }" lazy>
                <template #title>
                  {{ $t('messages') }} <i class="fa-solid fa-triangle-exclamation text-warning px-2 fa-bounce" v-if="notification.message"></i>
                </template>
                <MessageInbox
                  v-if="messageLoaded"
                  :id="'message-inbox-' + messageHash"
                  :messages="messages"
                  :unreadMessage="unreadMessages"
                  :username="username"
                  :hash="messageHash"
                  :lock="lock"
                  :orderView="true"
                  :admin-disable-input="user !== order.seller_username && user !== order.buyer_username"
                />
                <div v-else class="mx-auto my-5">
                  <b-spinner large label="Large Spinner"></b-spinner>
                  <div>{{ $t('message_loading') }}</div>
                </div>
              </b-tab>
              <b-tab v-if="order.dispute && (user === order.seller_username || user === order.buyer_username)" class="pt-1 pb-0" @click="getMessageDispute()" lazy>
                <template #title>
                  {{ $t('dispute') }} <i class="fa-solid fa-triangle-exclamation text-warning px-2 fa-bounce"></i>
                </template>
                <MessageInbox v-if="messageLoadedDispute && disputeMessageHash && messagesDispute.length"
                              :messages="messagesDispute"
                              :unreadMessage="unreadMessagesDispute"
                              :username="username"
                              :hash="disputeMessageHash"
                              :lock="disputeMessageLock"
                              id="dispute"
                              :orderView="true"/>
                <div v-else-if="(messageLoadedDispute && !disputeMessageHash) || (disputeMessageHash && disputeEmptyThread)">
                  <h6 class="p-2">{{ $t('dispute') }}</h6>
                  <p class="p-0 m-0">
                    <template v-if="isBuyer">{{ $t('dispute_evidence_buyer') }}</template>
                    <template v-else-if="isSeller">{{ $t('dispute_evidence_seller') }}</template>
                    <template v-else>{{ $t('dispute_evidence') }}</template>
                  </p>
                  <div id="task-form-error-details" class=" p-1   alert alert-danger text-center m-3" v-if="validation_message_switch">
                    <p class="p-0 m-0">{{ validation_message }}</p>
                  </div>
                  <div class="m-3">
                    <b-form-textarea
                      id="textarea"
                      v-model="disputeMessage"
                      :placeholder="$t('message_input_placeholder')"
                      rows="4"
                      max-rows="6"
                      class="m-0"
                    ></b-form-textarea>
                  </div>
                  <div class="send pb-3" align="center">
                    <b-button v-if="!submit" variant="success" @click="postSellerDisputeEvidence" block class="w-75">{{ $t('send_response') }}</b-button>
                    <b-button v-else variant="success" disabled="disabled" block class="w-75">
                      <b-spinner class="" small label="Small Spinner"></b-spinner>
                    </b-button>
                  </div>
                </div>
                <div v-else class="mx-auto my-5">
                  <b-spinner large label="Large Spinner"></b-spinner>
                  <div>{{ $t('dispute_loading') }}</div>
                </div>
              </b-tab>
              <b-tab :title="$t('invoice')" @click="loadInvoice()" v-if="currentOrderStatus.status !== 0">
                <invoices :orderHash="orderHash" v-if="loadInvoiceSwitch"/>
              </b-tab>
              <b-tab :title="$t('reviews')" v-if="currentOrderStatus.status === 5 || currentOrderStatus.status === 6" @click="getOrderReview()">
                <Review :review="reviews" v-if="reviewsLoaded" :orders-page="ordersPage" :orderStatus="currentOrderStatus.status" :portfolio="order.portfolio_permission"
                        :buyer="isBuyer" :dispute="order.dispute !== null" :orderHash="order.hash"/>
                <div v-else class="mx-auto my-5">
                  <b-spinner large label="Large Spinner"></b-spinner>
                  <div>{{ $t('reviews_loading') }}</div>
                </div>
              </b-tab>
              <b-tab title="ETN" lazy v-if="$store.getters.hasRole('ROLE_ADMIN_SELLER_PAYMENT')">
                <EtnPayments type="order" :hash="order.hash"/>
              </b-tab>
            </b-tabs>
          </b-card>

          <div class="banner-accept-container flex-md-row justify-content-md-center" v-if="isSeller && currentOrderStatus.status === 0">
            <p>
              <i>{{ $t('accept_order_message_p1') }}</i>&nbsp;<strong>{{ $t('accept_order_message_p2') }}</strong>&nbsp;{{
                $t('accept_order_message_p3')
              }}&nbsp;<strong>{{ $t('accept_order_message_p4') }}</strong>&nbsp;{{ $t('accept_order_message_p5') }}
            </p>
            <div>
              <b-button variant="primary" class="mt-md-0" v-on:click="decision('decisionTotal-' + order.hash)">{{ $t('accept_decline_button') }}</b-button>
            </div>
          </div>

        </div>
      </div>
      <decline :hash="order.hash" :title="$t('revision_request_title')" :heading="$t('revision_form_message')" :inbox-hash="order.thread.hash" v-on:revisionsSum="revisionsSum"/>
      <order-decision-modal :ref-id="'decisionTotal-' + order.hash" :hash="order.hash" :messageThread="order.thread.hash" v-if="order.thread"/>
    </div>
    <div v-else class="mx-auto my-5">
      <b-spinner large label="Large Spinner"></b-spinner>
      <div>{{ $t('loading_order') }}</div>
    </div>
    <decline :hash="order.hash" :title="$t('dispute_request')" :heading="$t('dispute_request_hint')" id="dispute" :buyer-check="isBuyer"/>
    <accept :hash="order.hash" id="model-accept-order" modalId="accept"/>
  </div>
</template>

<script>
import decline from '../../components/Modal/DeclineModal'
import accept from '../../components/Modal/AcceptedModal'
import Delivery from '../../components/ReceiveTask/Delivery'
import Timeline from '../../components/ReceiveTask/Timeline'
import ApiService from '../../apiService.js'
import MessageInbox from '../../components/MyAccount/Inbox/MessageInbox'
import Requirement from '../../components/ReceiveTask/Requirement'
import Review from '../../components/ReceiveTask/OrderReview'
import OrderDecisionModal from '../../components/Modal/OrderDecisionModal'
import Invoices from '../../components/Tabs/Invoices'
import helper from '../../helper'
import Breadcrumb from '../../components/Breadcrumbs/Breadcrumb'
import BannerAlert from '../../components/ReceiveTask/BannerAlert'
import EtnPayments from '../../components/Admin/Tabs/AdminEtnPayments'
import threadMessagesCreatedSubscription from '../../graphql/threadMessagesCreatedSubscription'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ReceiveTask',
  data () {
    return {
      ordersPage: true,
      messageHash: '',
      messageLoaded: false,
      requirementsLoaded: false,
      reviewsLoaded: false,
      messageInput: '',
      unreadMessages: [],
      messages: [],
      username: '',
      requirements: [],
      order: [],
      lock: false,
      due_date: '',
      unreadMessagesDispute: [],
      messagesDispute: [],
      messageLoadedDispute: false,
      disputeMessageLock: false,
      disputeMessageHash: '',
      disputeMessage: '',
      disputeEmptyThread: false,
      validation_message: '',
      validation_message_switch: false,
      submit: false,
      timeline: [],
      OrderReceive: false,
      revisions: 0,
      description: '',
      orderHash: this.$route.params.hash,
      loadInvoiceSwitch: false,
      reviews: null,
      disputeStatusNotAllowed: [6, 5, 2, 8, 9, 0],
      deliveredStatusNotAllowed: [0, 9, 2, 8, 6, 5],
      tabIndex: null,
      user: localStorage.getItem('username'),
      customQuote: null,
      currentOrderStatus: null,
      breadcrumbs: [],
      notification: {
        message: false
      }
    }
  },
  components: {
    BannerAlert,
    Breadcrumb,
    Requirement,
    Review,
    Timeline,
    Delivery,
    decline,
    MessageInbox,
    accept,
    OrderDecisionModal,
    Invoices,
    EtnPayments
  },
  async created () {
    await this.processJwt()
  },
  mounted () {
    this.getOrder()
  },
  watch: {
    '$route.hash': function (id) {
      if (id === '#delivered' && this.OrderReceive === true) {
        this.getOrder()
      }
    },
    tabIndex (newIndex) {
      if (newIndex === 3) {
        this.notification.message = false
      }
    }
  },
  methods: {
    ...mapActions([
      'processJwt'
    ]),
    loadInvoice () {
      this.loadInvoiceSwitch = true
    },
    date (date) {
      if (date === null) {
        return this.$t('no_payment_date_message')
      }
      const newDate = new Date(date)
      return newDate.toLocaleDateString()
    },
    dateDiffInDays (date) {
      return helper.dateDiffInDays(date)
    },
    getOrder (modal = null) {
      this.OrderReceive = false
      ApiService.getOrder(this.$route.params.hash).then(response => {
        this.order = response.data.data
        this.currentOrderStatus = this.order.status[this.order.status.length - 1]
        this.revisions = this.order.revisions_left
        this.customQuote = this.order.custom

        let date = this.currentOrderStatus.date_created
        const myDate = new Date(date)
        let dateConvert = this.order.items[0].package_delivery / 24
        myDate.setDate(myDate.getDate() + dateConvert)
        this.due_date = this.date(myDate)
        this.username = localStorage.getItem('username')

        this.description = this.order.items[0].description

        if (this.order.thread !== null) {
          if (this.order.thread.locked) {
            this.lock = true
          }
          this.messageHash = this.order.thread.hash
          this.getMessage(this.messageHash)
        }

        this.getRequirements()
        this.getTimeline()

        if (modal) {
          modal.hide()
        }
        if (window.location.hash === '#delivered') {
          this.tabIndex = 2
          this.clearHash()
        }

        if (this.$store.getters.hasRole('ROLE_ADMIN_VIEW_ORDER_DETAILS')) {
          this.breadcrumbs = [
            { 'name': this.$t('admin_dashboard'), 'link': this.$router.resolve({ name: 'admin' }).href },
            { 'name': 'Orders', 'link': this.$router.resolve({ name: 'admin_orders' }).href },
            { 'name': this.order.hash, 'link': this.$router.resolve({ name: 'admin_order', params: { hash: this.order.hash } }).href }
          ]
        } else if (this.order.seller_username === this.username) {
          this.breadcrumbs = [
            { 'name': this.$t('order'), 'link': this.$router.resolve({ name: 'view_my_sales' }).href },
            { 'name': this.order.hash, 'link': this.$router.resolve({ name: 'deliver_task', params: { hash: this.order.hash } }).href }
          ]
        } else {
          this.breadcrumbs = [
            { 'name': this.$t('order'), 'link': this.$router.resolve({ name: 'view_my_purchases' }).href },
            { 'name': this.order.hash, 'link': this.$router.resolve({ name: 'receive_task', params: { hash: this.order.hash } }).href }
          ]
        }
      }).catch(() => {
      })
    },
    getTimeline () {
      ApiService.getOrderTimeline(this.$route.params.hash).then(response => {
        this.timeline = response.data.data
      })
    },
    getMessage (hash) {
      this.messageLoaded = false
      ApiService.getMessages(hash).then(response => {
        let lastHash = ''

        this.messages = response.data.data
        this.messages.forEach(function (message) {
          if (message.hash === lastHash) {
            message.repeat = true
          } else {
            message.repeat = false
            lastHash = message.hash
          }
        })

        this.unreadMessages = response.data.data.filter(function (message) {
          return message.read < 1
        })

        this.messageLoaded = true

        if (!this.$apollo.subscriptions.messagesCreated) {
          this.$apollo.addSmartSubscription('messagesCreated', {
            query: threadMessagesCreatedSubscription,
            variables () {
              return {
                clientHash: this.getUserHash(),
                threadHash: hash
              }
            },
            result ({ data }) {
              if (data.onCreateMessage) {
                const payload = data.onCreateMessage
                ApiService.getMessage(payload.thread_hash, payload.message_hash).then((resp) => {
                  this.unreadMessages.push(resp.data.data)
                  this.$nextTick(() => {
                    const el = document.getElementById('message-inbox-' + payload.thread_hash)
                    if (!el) {
                      this.notification.message = true
                    }
                  })
                })
              }
            }
          })
        }
      }).catch(() => {
      })
    },
    getMessageDispute () {
      this.messageLoadedDispute = false
      ApiService.getDispute('order', this.order.dispute.hash).then(response => {
        if (response.data.data) {
          this.disputeMessageHash = response.data.data.hash
          this.disputeMessageLock = !!response.data.data.locked
          let messages = []
          ApiService.getMessages(response.data.data.hash).then(response => {
            if (response.status !== 204) {
              messages = response.data.data
              this.unreadMessagesDispute = messages.filter(function (message) {
                return message.read < 1
              })
            } else {
              this.disputeEmptyThread = true
            }
            this.messagesDispute = messages
            this.messageLoadedDispute = true

            const hash = this.disputeMessageHash
            if (!this.$apollo.subscriptions.disputeMessagesCreated) {
              this.$apollo.addSmartSubscription('disputeMessagesCreated', {
                query: threadMessagesCreatedSubscription,
                variables () {
                  return {
                    clientHash: this.getUserHash(),
                    threadHash: hash
                  }
                },
                result ({ data }) {
                  if (data.onCreateMessage) {
                    const payload = data.onCreateMessage
                    ApiService.getMessage(payload.thread_hash, payload.message_hash).then((resp) => {
                      this.unreadMessagesDispute.push(resp.data.data)
                    })
                  }
                }
              })
            }
          }).catch(() => {
          })
        } else {
          this.disputeMessageHash = response.data.data
          this.messageLoadedDispute = true
        }
      }).catch(() => {
      })
    },
    getRequirements () {
      ApiService.getRequirements(this.$route.params.hash).then(response => {
        if (response.status !== 204) {
          this.requirements = response.data.data
        }
        this.requirementsLoaded = true
        this.OrderReceive = true
      }).catch(() => {
      })
    },
    getOrderReview () {
      ApiService.getReview(this.$route.params.hash).then(response => {
        this.reviews = response.data.data
        this.reviewsLoaded = true
      }).catch(() => {
      })
    },
    postSellerDisputeEvidence () {
      this.validation_message_switch = false
      this.validation_message = ''
      this.submit = true
      if (this.disputeMessage.length === 0) {
        this.text_validation = true
        this.validation_message = this.$t('message_validation')
        this.validation_message_switch = true
        this.submit = false
      } else {
        if (this.disputeEmptyThread && this.disputeMessageHash) {
          this.messageLoadedDispute = false
          ApiService.postMessage(this.disputeMessageHash, this.disputeMessage).then(response => {
            this.disputeEmptyThread = false
            this.getMessageDispute()
          }).catch(() => {
          })
        } else {
          ApiService.disputePatch(this.$route.params.hash, { 'message': this.disputeMessage }).then((response) => {
            this.getMessageDispute()
          })
        }
      }
    },
    accepted () {
      this.getOrder()
    },
    revisionsSum () {
      this.revisions = this.revisions - 1
    },
    redirect () {
      this.$router.push('/my-account/orders/deliver/' + this.order.hash)
    },
    decision (id) {
      this.$bvModal.show(id)
    },
    clearHash () {
      if (window.location.hash === '#delivered') {
        window.location.hash = ''
      }
    },
    isOrderPassDue () {
      const currentStatus = this.currentOrderStatus.status
      return this.dateDiffInDays(this.order.due_date) < 0 && (currentStatus === 0 || currentStatus === 1 || currentStatus === 3 || currentStatus === 4)
    },
    isOrderInAction () {
      const currentStatus = this.currentOrderStatus.status
      return !(currentStatus === 2 || currentStatus >= 5)
    },
    getDisplayPrice (price, currency, rate) {
      return helper.currencyConversion(price, currency, rate)
    },
    isCustomTask () {
      return this.order.items[0].gig_title.substr(0, 8).indexOf('[CUSTOM]') > -1
    },
    getOriginalSlug () {
      if (this.isCustomTask(this.order.items[0].gig_title)) {
        ApiService.getTaskByUser(this.order.seller_username, this.order.items[0].gig_slug).then(response => {
          const data = response.data.data[0]
          this.$router.push('/' + data.user.username + '/' + data.original_slug)
        })
      } else {
        this.$router.push('/' + this.order.seller_username + '/' + this.order.items[0].gig_slug)
      }
    }
  },
  destroyed () {
    if (this.$apollo.subscriptions.messagesCreated) {
      this.$apollo.subscriptions.messagesCreated.skip = true
    }
    if (this.$apollo.subscriptions.disputeMessagesCreated) {
      this.$apollo.subscriptions.disputeMessagesCreated.skip = true
    }
  },
  computed: {
    ...mapGetters(['getUserHash']),
    isBuyer () {
      return this.order.buyer_username === this.username
    },
    isSeller () {
      return this.order.seller_username === this.username
    },
    getProcessWordingAndClass () {
      let result = {
        name: this.$t('unknown'),
        class: ''
      }

      switch (this.currentOrderStatus.status) {
        case this.isSeller && 0:
          result.name = '🎉 ' + this.$t('new_order')
          result.class = 'new-order-span d-block'
          break
        case 0:
          result.name = this.$t('job_waiting_for_decision')
          break
        case 1:
          result.name = this.$t('in_progress')
          break
        case 2:
          result.name = this.$t('job_declined')
          break
        case 3:
          result.name = this.$t('in_progress')
          break
        case 4:
          if (this.isBuyer) {
            result.name = '🎉 ' + this.$t('delivered')
            result.class = 'new-order-span d-block'
          } else {
            result.name = this.$t('delivered')
          }
          break
        case 5:
          result.name = this.$t('completed')
          break
        case 6:
          result.name = this.$t('refund')
          break
        case 7:
          result.name = this.$t('dispute')
          break
        case 8:
          result.name = this.$t('cancelled')
          break
        case 9:
          result.name = this.$t('expired')
          break
      }
      return result
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  height: 100px;
  object-fit: cover;
}

.text-primary strong {
  color: #44aae1;
}

.disclaimer p {
  font-size: 0.75em;
}

.side-details {
  top: 14.5%;
  overflow-x: hidden;
  z-index: 1;
}
</style>
<style lang="scss">

.banner-accept-container {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 15px;
  z-index: 999;
  width: 100%;
  box-shadow: $mobile-cta-shadow;
  background-color: white;

  button {
    width: 100%;
  }

  @include md-up {
    position: relative;
    box-shadow: none;
    background-color: transparent;
    margin: auto;
    max-width: 75%;
    button {
      width: auto;
    }
  }
}

.nav-tabs .nav-link {
  color: $brand-sky;
}

.order-details-callout {
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 8px;
  font-size: 1rem;
}

.side-details {
  border: 1px solid rgba(0, 0, 0, 0.05);

  .card {
    margin: 0 0 0 0;
    border: none;
  }

  a {
    color: #000;

    &:hover {
      color: #000;
    }
  }

  .card-text {
    word-wrap: break-word;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 5em;
    line-height: 1.5em;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .card-header {
    h5 {
      padding: 0.15rem !important;
    }
  }
}

.text-primary {
  color: #44aae1 !important;
}

.receive-task .lineThrough {
  background: #ffffff !important;
  padding: 0 10px;
}

.receive-task .dz-preview {
  pointer-events: all !important;
}

.new-order-span {
  animation: scaleUpBounce 1s ease-in-out;
  display: block;
}

.line-container .item-circle {
  border-color: $brand-tangerine !important;
}

// hide help on mobile
#content + footer + a {
  display: none;
}

@include md-up {
  #content + footer + a {
    display: block;
  }
}

@keyframes scaleUpBounce {
  0% {
    transform: scale(0.5);
  }
  70% {
    transform: scale(1.2);
  }
  80% {
    transform: scale(0.9);
  }
  90% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
