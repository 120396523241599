<template>
  <div>
    <div v-if="!loaded" class="mx-auto my-5">
      <b-spinner large label="Large Spinner"></b-spinner>
      <div>Loading</div>
    </div>
    <div v-else-if="etnPayments.length === 0">
      <p>Sorry no ETN payment was sent</p>
    </div>
    <template v-else>
      <div class="card mb-3" v-for="(payment, index) in etnPayments" :key="index">
        <div class="card-header bg-transparent d-flex justify-content-between">
          <div><label>Status:</label> {{ getProcessWording(payment.status) }}</div>
          <div><label class="m-0">Date Created:</label> {{ (new Date(payment.date_created)).toLocaleString() }}</div>
        </div>
        <div class="card-body text-left">
          <div class="d-flex justify-content-between">
            <div v-if="type === 'user'"><label class="m-0">Order Number:</label> <router-link :to="{name: 'admin_order', params: { hash: payment.order.hash }}">{{ payment.order.hash }}</router-link></div>
            <div><label class="m-0">Amount:</label> {{ payment.amount }} ETN</div>
            <div><label>Reference:</label> {{ payment.reference ? payment.reference : 'N/A' }}</div>
          </div>
          <div>
            <label class="m-0">Error Message:</label> {{ payment.error_message }}
          </div>
        </div>
        <div class="card-footer">
          <span class="text-break small">{{ payment.etn_wallet }}</span>
        </div>
      </div>

      <div v-if="type === 'user' && pagination.total > pagination.per_page">
        <Pagination :perPage="pagination.per_page" :currentPage="pagination.page" :total="pagination.total" v-on:paginationUpdate="getUserPayment" class="mt-3"/>
      </div>

    </template>
  </div>
</template>

<script>
import ApiService from '../../../apiService'
import Pagination from '../../Global/Pagination'

export default {
  name: 'AdminEtnPayments',
  components: { Pagination },
  props: {
    type: {
      type: String,
      required: true
    },
    hash: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loaded: false,
      etnPayments: [],
      pagination: {
        page: 1,
        per_page: 25,
        total: 0
      }
    }
  },
  created () {
    if (this.type === 'order') {
      this.getOrderPayment()
    }

    if (this.type === 'user') {
      this.getUserPayment()
    }
  },
  methods: {
    getProcessWording (status) {
      if (status === 1) {
        return 'Success'
      }
      if (status === 2) {
        return 'Canceled'
      }
      return 'Processing'
    },
    getUserPayment (page = 1) {
      this.loaded = false
      ApiService.getUserEtnPayment(this.hash, page, this.pagination.per_page).then((resp) => {
        this.etnPayments = resp.data.data.results
        this.pagination = resp.data.data.pagination
        this.loaded = true
      })
    },
    getOrderPayment () {
      ApiService.getOrderEtnPayment(this.hash).then((resp) => {
        this.etnPayments = resp.data.data
        this.loaded = true
      })
    }
  }
}
</script>
