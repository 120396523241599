<template>
  <b-modal :id="id" :ref="id + 'Modal'" hide-footer
           @show="resetModal"
           @hidden="resetModal"
           size="lg"
           header-class="headerDisputePadding"
           body-class="bodyDisputePadding"
  >
    <div class=" mx-3 pl-3 pr-3 pb-3 pt-0">
      <h4 class="text-center font-weight-bold mb-3">{{ title }}</h4>
      <h6 class="alert alert-warning" v-if="id === 'dispute'">{{ $t('dispute_warning') }}</h6>
      <p class="alert alert-danger text-center" v-if="validation_message_switch">{{ validation_message }}</p>
      <div class="mb-2">{{ heading }}</div>
      <div>
        <b-form-textarea
          id="textarea"
          v-model="text"
          :placeholder="$t('message_input_placeholder')"
          rows="4"
          max-rows="6"
          class="m-0"
        ></b-form-textarea>
      </div>

      <div v-if="id === 'decline'" class="mt-5 mb-3">
        <div class="mb-2">{{$t('revisions_attachments')}}</div>
        <vue-dropzone ref="myVueDropzone" id="dropzone" class="p-4" :options="dropzoneOptions" :useCustomSlot=true
                      :awss3="awss3"
                      v-on:vdropzone-s3-upload-error="s3UploadError"
                      v-on:vdropzone-s3-upload-success="s3UploadSuccess"
                      v-on:vdropzone-files-added="preFileUpload"
                      v-on:vdropzone-removed-file='dropzoneRemoved'>
          <div class="dropzone-custom-content text-muted">
            <i class="fa-solid fa-cloud-arrow-up"></i>
            <br />
            {{ $t("dropzone_placeholder")}}
          </div>
        </vue-dropzone>
      </div>

      <div class="mt-2 pt-1" v-if="id === 'dispute' && buyerCheck">
        <hr>
        <h4 class="text-center font-weight-bold">{{$t('dispute_review_title')}}</h4>
        <p class="alert alert-danger text-center" v-if="validation_review_switch">{{ validation_review_message }}</p>
        <div class="field-heading pt-2">{{ $t('reviews_form_rating_heading') }}</div>
        <star-rating
          v-model="rating"
          v-bind:star-size="25"
          v-bind:increment="1"
        />

        <div class="field-heading pt-3 mb-2">{{ $t('reviews_form_message_heading') }}</div>

        <b-form-input
          id="textarea"
          v-model="reviewTitle"
          :placeholder="$t('reviews_placeholder_title')"
          class="m-0 mb-3"
          v-bind:class="{ 'border-danger': (review_validation_title === true)}"
        />

        <b-form-textarea
          id="textarea"
          v-model="reviewMessage"
          :placeholder="$t('reviews_placeholder')"
          rows="3"
          max-rows="6"
          class="m-0"
          v-bind:class="{ 'border-danger': (review_validation === true)}"
        />
        {{reviewMessage.length}}
        <div class="pt-2 small">{{ $t('characters_remaining') }}: <span v-bind:style="reviewMessage.length > 400 ? 'color: red;' : 'color: green;' ">{{ 400 - this.reviewMessage.length }}</span></div>
        <p class="pt-2">{{$t('dispute_review_advice')}}</p>
      </div>
      <div class="send pt-3" align="center">
        <b-button v-if="!submit && id === 'decline'" variant="primary" :disabled="fileUploading" @click="submitMessage" block class="w-75">{{ $t('send_response') }}</b-button>
        <b-button v-else-if="!submit && id === 'dispute'" variant="primary" :disabled="reviewMessage.length > 400" @click="submitMessage" block class="w-75">{{ $t('send_dispute_evidence') }}</b-button>
        <b-button v-else variant="primary" disabled="disabled" block class="w-75">
          <b-spinner class="" small label="Small Spinner"></b-spinner>
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import apiService from '../../apiService.js'
import StarRating from 'vue-star-rating'
import vueDropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
export default {
  name: 'decline',
  props: {
    hash: String,
    title: String,
    heading: String,
    id: {
      type: String,
      required: false,
      default: 'decline'
    },
    buyerCheck: {
      type: Boolean,
      required: false,
      default: false
    },
    inboxHash: {
      type: String,
      required: false,
      default: ''
    }
  },
  components: {
    StarRating,
    vueDropzone
  },
  mounted () {
    this.resetModal()
  },
  data () {
    return {
      show: true,
      text: '',
      reviewTitle: null,
      validation_message: '',
      validation_message_switch: false,
      submit: false,
      rating: 0,
      reviewMessage: '',
      review_validation: false,
      validation_review_switch: false,
      validation_review_message: '',
      review_validation_title: false,
      files: [],
      ErrorFile: '',
      awss3: {
        signingURL: apiService.getTokenUrl('message'),
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('accessToken')
        },
        params: {
          'hash': this.inboxHash
        },
        sendFileToServer: false
      },
      fileUploading: false,
      dropzoneOptions: {
        paramName: 'file',
        thumbnailWidth: 100,
        thumbnailHeight: 100,
        maxFilesize: 500,
        maxFiles: 5,
        autoProcessQueue: true,
        parallelUploads: 2,
        timeout: 0,
        parallelChunkUploads: false,
        retryChunks: true,
        retryChunksLimit: 3,
        addRemoveLinks: true,
        dictCancelUpload: this.$t('cancel'),
        dictRemoveFile: this.$t('delete'),
        renameFile (file) {
          // eslint-disable-next-line no-return-assign
          return new Date().valueOf() + '_' + file.name.replace(/[^0-9a-z._-]/gi, '')
        }
      }
    }
  },
  methods: {
    s3UploadError (errorMessage) {
      this.fileUploading = false
      this.validation_message_switch = true
      this.validation_message = errorMessage
    },
    s3UploadSuccess (s3ObjectLocation) {
      this.fileUploading = false
      this.files.push(s3ObjectLocation)
    },
    preFileUpload () {
      this.fileUploading = true
    },
    dropzoneRemoved (file) {
      let array = this.files
      for (let i = 0; i < array.length; i++) {
        let imageName = array[i].split('%2F').pop()
        if (imageName === file.upload.filename) {
          array.splice(i, 1)
          break
        }
      }
    },
    resetModal () {
      this.text = ''
      this.files = []
      this.validation_message_switch = false
      this.validation_message = ''
      this.submit = false
      this.rating = 0
      this.reviewMessage = ''
      this.review_validation = false
      this.validation_review_switch = false
      this.validation_review_message = ''
      this.reviewTitle = null
      this.review_validation_title = false
    },
    submitMessage () {
      this.validation_message_switch = false
      this.validation_message = ''
      this.review_validation = false
      this.validation_review_switch = false
      this.validation_review_message = ''
      this.submit = true
      if (this.text.length === 0) {
        this.validation_message = this.$t('message_validation')
        this.validation_message_switch = true
        this.submit = false
      } else {
        let uploadData = {
          'message': this.text
        }
        if (this.id === 'decline') {
          if (this.files.length > 0) {
            uploadData['files'] = this.files
          }
          apiService.submitRevision(this.hash, uploadData).then((data) => {
            this.$bvModal.hide(this.id)
            this.$emit('revisionsSum')
          }).catch((error) => {
            this.errorHandling(error)
          })
        } else if (this.id === 'dispute' && this.buyerCheck) {
          if (this.reviewMessage.length !== 0 || this.rating !== 0) {
            if (this.reviewValidation()) {
              uploadData = {
                'message': this.text,
                'rating_message': this.reviewMessage,
                'rating': this.rating
              }

              if (this.reviewTitle !== null && this.reviewTitle.length !== 0) {
                uploadData['rating_title'] = this.reviewTitle
              }

              this.submitDispute(uploadData)
            }
          } else {
            this.submitDispute(uploadData)
          }
        } else if (this.id === 'dispute') {
          this.submitDispute(uploadData)
        }
      }
    },
    reviewValidation () {
      if (this.reviewMessage.length === 0) {
        this.review_validation = true
        this.validation_review_message = this.$t('message_review_validation')
        this.validation_review_switch = true
        this.submit = false
        return false
      } else if (!(this.rating >= 1 && this.rating <= 5)) {
        this.validation_review_message = this.$t('rating_validation')
        this.validation_review_switch = true
        this.submit = false
        return false
      }
      return true
    },
    errorHandling (error) {
      if (error.response.data.code === 'HTTP-400') {
        if (error.response.data.errors.children.message.errors !== undefined) {
          this.validation_message = error.response.data.errors.children.message.errors[0]
          this.validation_message_switch = true
        } else if (error.response.data.errors.children.files !== undefined && error.response.data.errors.children.files.errors !== undefined) {
          this.validation_message = error.response.data.errors.children.files.errors[0]
          this.validation_message_switch = true
        } else if (error.response.data.errors.children.rating.errors !== undefined) {
          this.validation_review_message = error.response.data.errors.children.rating.errors[0]
          this.validation_review_switch = true
        } else if (error.response.data.errors.children.rating_message.errors !== undefined) {
          this.validation_review_message = error.response.data.errors.children.rating_message.errors[0]
          this.validation_review_switch = true
          this.review_validation = true
        } else if (error.response.data.errors.children.rating_title.errors !== undefined) {
          this.validation_review_message = error.response.data.errors.children.rating_title.errors[0]
          this.validation_review_switch = true
          this.review_validation_title = true
        }
      } else {
        this.validation_message = error.response.data.error_message
        this.validation_message_switch = true
      }
      this.submit = false
    },
    submitDispute (form) {
      apiService.disputeCreate(this.hash, form).then((data) => {
        this.$refs.disputeModal.hide()
        this.$parent.getOrder()
      }).catch((error) => {
        this.errorHandling(error)
      })
    }
  }
}
</script>

<style scoped>
  /deep/ .headerDisputePadding {
    padding: 1px 6px 4px 0;
    border-bottom: 0;
  }
  /deep/ .bodyDisputePadding{
    padding: 0;
  }
  h6 {
    text-align: left;
  }
</style>
