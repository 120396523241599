<template>
  <div class="banner-status bg-navy mb-md-3 d-md-flex justify-content-md-between">
    <div class="align-self-center text-white text-md-left">
      <span class="d-lg-inline-flex font-weight-bold">{{ title }}</span> {{ subtitle }}
    </div>
    <div class="align-self-center">
      <b-button class="mt-md-0" variant="primary" @click="buttonClicked">{{ button }}</b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BannerAlert',
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: false
    },
    button: {
      type: String,
      required: true
    }
  },
  methods: {
    buttonClicked () {
      this.$emit('buttonClicked')
    }
  }
}
</script>
<style lang="scss">
  .banner-status {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 15px;
    z-index: 999;
    width: 100%;
    box-shadow: $mobile-cta-shadow;
    span {
      display: block;
    }
    button {
      margin-top: 10px;
      width: 100%;
    }
    @include md-up {
      position: relative;
      box-shadow: none;
      border-radius: 0.25rem;
    }
  }
</style>
